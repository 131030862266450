import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, Flex } from '../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="যোগাযোগ" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  যোগাযোগের ঠিকানা
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/contact/',
        name: 'যোগাযোগ',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <Flex mx={[0, -2]} flexWrap="wrap" mt={3} mdxType="Flex">
        <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">
বালক শাখা ও অফিস: ২১৯/৪/খ, ভিপি রোড, দক্ষিণ পীরেরবাগ (৬০ ফিট ভাঙ্গাব্রীজ সংলগ্ন), ঢাকা-১২১৬।
          <p>{`বালিকা শাখা: ২৪৬/১/বি-৫/১, পশ্চিম আগারগাঁও (৬০ ফিট ভাঙ্গাব্রীজ ও ৬ তলা গার্মেন্টস সংলগ্ন), ঢাকা-১২০৭।`}</p>
          <p>{`মোবাইল: 01914-723676`}</p>
          <p>{`মেইল: `}<a parentName="p" {...{
              "href": "mailto:madrasatulilm.contact@gmail.com"
            }}>{`madrasatulilm.contact@gmail.com`}</a></p>
        </Box>
        <Box width={[1, 1 / 2]} px={3} py={3} mdxType="Box">  
 <img src={`/assets/images/map.jpeg`} alt={"map"} style={{
            width: '100%',
            height: "auto"
          }} />
        </Box>
      </Flex>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      